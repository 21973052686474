<template>
  <div id="my-bookmarks">
    <b-row>
      <b-col cols="6" class="mx-auto">
        <b-card no-body :title="$t('dashboard.MyBookmarks')">
          <b-card-header class="border-bottom p-2">
            <h4 class="mb-0">{{ $t("dashboard.MyBookmarks") }}</h4>
          </b-card-header>
          <b-card-body class="p-0">
            <div class="alert alert-danger p-1 m-1" v-if="error">{{ error }}</div>
            <div class="text-center text-muted py-3" v-if="bookmarks.length < 1 && !loading">
              Sorry! no bookmarks
            </div>
            <div id="bookmarks">
              <b-media
                class="border-bottom p-1"
                v-for="(profile, index) in bookmarks"
                :key="`bookmarks${index}`"
                ><template #aside v-if="profile.files.length">
                  <b-link target="_blank" :to="`/profile/details/${profile._id}`"
                    ><img height="50" class="rounded" :src="profile.files[0].fileUrl"
                  /></b-link>
                </template>
                <div class="d-flex">
                  <div class="profileData">
                    <b-link target="_blank" :to="`/profile/details/${profile._id}`"
                      ><h5>{{ profile.name }}</h5></b-link
                    >
                    <p class="text-muted">
                      {{ $t(`Timeline.${capatalized(profile.profileType)}`) }}
                    </p>
                  </div>
                  <div class="ml-auto">
                    <b-button
                      variant="outline-danger"
                      size="sm"
                      @click="removeBookmark(profile._id)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </div>
                </div>
              </b-media>
            </div>
            <b-button
              @click="loadBookmarks"
              v-if="!error && loadedBookmarks < totalBookmarks && totalBookmarks.length < 1"
              block
              variant="link"
              :disabled="loading"
              >{{ loading ? $t("Timeline.Loading") : $t("Timeline.Load more") }}</b-button
            >
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import store from "@/store";

import {
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BRow,
  BLink,
  BButton,
  BMedia,
  BAvatar,
} from "bootstrap-vue";
export default {
  name: "BookmarksList",
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BRow,
    BLink,
    BButton,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      bookmarks: [],
      error: "",
    };
  },

  data() {
    return {
      bookmarks: [],
      totalBookmarks: 0,
      loadedBookmarks: 0,
      currentPage: 1,
      perPage: 6,
      loading: false,
      error: "",
    };
  },
  created() {
    store.commit("appConfig/UPDATE_NAV_MENU_HIDDEN", false);
    store.commit("appConfig/UPDATE_FOOTER_CONFIG", { type: "static" });
    store.commit("appConfig/UPDATE_NAVBAR_CONFIG", { type: "sticky" });
  },
  methods: {
    loadBookmarks: async function () {
      try {
        const formData = new FormData();
        formData.append("page", this.currentPage);
        formData.append("perPage", this.perPage);
        this.loading = true;
        const { data } = await axios.post("/users/bookmarks/list/", formData);
        this.totalBookmarks = data.total;
        this.bookmarks = [...this.bookmarks, ...data.data.bookmarks];
        this.loadedBookmarks += data.data.bookmarks.length;
        this.loading = false;
        this.currentPage += 1;
      } catch (e) {
        this.loading = false;
        this.error = e.message;
      }
    },
    removeBookmark: async function (id) {
      this.bookmarks = this.bookmarks.filter((bookmark) => bookmark._id !== id);
      try {
        const formData = new FormData();
        formData.append("profileId", id);
        const { data } = await axios.post("bookmarks", formData);
      } catch {
        this.error = e.message;
      }
    },
    capatalized: function (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    capatalized: function (input) {
      return input.charAt(0).toUpperCase() + input.slice(1);
    },
  },
  mounted() {
    this.loadBookmarks();
  },
};
</script>
